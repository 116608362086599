<template>
  <div class="evaluate-answer-sheet">
    <EvaluateHeader :name="evaluation.name + '（综合素质测评）'">
    </EvaluateHeader>
    <div class="answer-box">
      <div class="answer-left-box">
        <div class="student-header-box">
          <i class="el-icon-arrow-left" @click="next(0)"></i>
          <div>
            <img v-if="indexStudent.avatar" :src="indexStudent.avatar" alt="" />
            <img v-else :src="avatarImg" alt="" />
            <p>{{ indexStudent.name || " - - " }}</p>
            <div class="tips-list">
              <span
                >{{ indexStudent.level == 3 ? "高" : "初"
                }}{{ indexStudent.year }}级</span
              >
              <span>{{ indexStudent.classNum }}班</span>
            </div>
          </div>
          <i class="el-icon-arrow-right" @click="next(1)"></i>
        </div>
        <div class="role-list-box">
          <div class="i-box" @click="scroll(0)">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div ref="roleBox" class="role-list">
            <div
              v-for="(v, i) in roleList"
              :key="i"
              :class="{
                'role-on': i == indexRole,
              }"
              @click="checkTeacher(v, i)"
            >
              {{ v.teacherRole | setName(teacherRoleList)
              }}<template v-if="v.subjectId">{{
                v.subjectId | setName(filterSubject)
              }}</template>
              {{ v.totalMark + "/" + v.total }}
            </div>
          </div>
          <div class="i-box i-box-right" @click="scroll(1)">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="search-student-box">
          班级
          <el-select
            v-model="search.classNum"
            placeholder="全部"
            clearable
            @change="getStudents"
          >
            <el-option
              v-for="item in classList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          状态
          <el-select
            v-model="search.isMark"
            placeholder="全部"
            clearable
            @change="getStudents"
          >
            <el-option
              v-for="item in isMarkList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          搜索
          <el-input
            v-model="search.name"
            placeholder="请输入内容"
            @keyup.enter.native="getStudents"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="getStudents"
            >
            </i>
          </el-input>
        </div>
        <div class="student-list-box edit-scroll-style">
          <div
            v-for="(v, i) in studentList"
            :key="i"
            class="student-list"
            :class="{ 'student-on': v.studentId == indexStudent.studentId }"
            @click="checkStudent(v)"
          >
            <div>
              <img v-if="v.avatar" :src="v.avatar" alt="" />
              <img v-else :src="avatarImg" alt="" />
              <span>{{ v.name }}</span>
              <span class="class-num">{{ v.classNum }}</span>
            </div>
            <div v-if="v.isMark == 0" class="tips">未考评</div>
            <div v-if="v.isMark == 1" class="tips tips-succ">已考评</div>
          </div>
        </div>
      </div>
      <div v-if="studentList.length > 0" class="question-box">
        <div v-if="roleList.length > 0" class="question-box-title">
          {{ roleList[indexRole].teacherRole | setName(teacherRoleList)
          }}<template v-if="roleList[indexRole].subjectId">{{
            roleList[indexRole].subjectId | setName(filterSubject)
          }}</template
          >（考评）
        </div>
        <div ref="scrollRef" class="question-list-box edit-scroll-style">
          <evaluateType
            v-for="(v, i) in questionList"
            :ref="'evaluate' + (i + 1)"
            :key="i"
            :question="v"
            :type="false"
            :index="i + 1"
            :student-score-list="studentScoreList"
          >
          </evaluateType>
          <div class="btn-box">
            <el-button type="primary" :loading="btnLoading" @click="submit()"
              >提交</el-button
            >
          </div>
        </div>
      </div>
      <div v-else class="none-data">
        <img src="@/static/academic/nodata.png" alt="" />
        <div>暂无考评内容</div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="250px"
      class="model-box"
    >
      <div class="tips-model">
        <i class="el-icon-success"></i>
        <div>考评提交成功</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goOut()">退出考评</el-button>
        <el-button type="primary" @click="next(1)">下一个</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const isMarkList = [
  { label: "已考评", value: 1 },
  { label: "未考评", value: 0 },
];
import evaluateType from "./component/evaluate-type";
import EvaluateHeader from "./component/evaluate-header";
import {
  evaluationGetbyid,
  evaluationteacherGetRoles,
  getStudents,
  getClassnums,
  getQuestions,
  studentScore,
  evaluationteacherSubmit,
} from "@/core/api/evaluate/evaluate";
import { teacherRoleList } from "@/core/util/constdata";
import { filterSubject } from "@/core/util/util";
export default {
  name: "EvaluateAnswerSheet",
  components: { EvaluateHeader, evaluateType },
  data() {
    return {
      teacherRoleList: teacherRoleList,
      evaluation: {
        name: "",
      },
      roleList: [],
      indexRole: 1,
      studentList: [],
      indexStudent: {},
      classList: [],
      isMarkList: isMarkList,
      filterSubject: filterSubject(),
      search: {
        classNum: "",
        isMark: "",
        name: "",
      },
      dialogVisible: false,
      btnLoading: false,
      questionList: [],
      studentScoreList: [],
      avatarImg: "https://static.wtjy.com/resource/avatar/131.jpg",
    };
  },
  created() {
    this.evaluationGetbyid();
    this.evaluationteacherGetRoles();
    this.getClassnums();
  },
  methods: {
    goOut() {
      this.$router.go(-1);
    },
    next(val) {
      try {
        this.studentList.map((item, index) => {
          if (this.indexStudent.studentId == item.studentId) {
            if (val) {
              if (index == this.studentList.length - 1) {
                return;
              }
              this.indexStudent = this.studentList[index + 1];
              this.studentScore();
              window.scrollTo(0, 0);
              this.$refs.scrollRef.scrollTop = 0;
              throw true;
            } else {
              if (index == 0) {
                return;
              }
              this.indexStudent = this.studentList[index - 1];
              this.studentScore();
              window.scrollTo(0, 0);
              this.$refs.scrollRef.scrollTop = 0;

              // this.
            }
          }
        });
      } catch (e) {
        // console.log(e);
        // throw e;
      }
      this.dialogVisible = false;
    },
    checkStudent(v) {
      this.indexStudent = v;
      this.studentScore();
    },
    submit() {
      // console.log(this.questionList);
      // evaluateType
      let optionArr = [];
      let nullOptionArr = [];
      this.questionList.map((item, index) => {
        let refStr = "evaluate" + (index + 1);
        if (item.type == 0) {
          let radio = this.$refs[refStr][0].radio;
          radio = radio.toString();
          optionArr.push({
            answer: radio,
            evaluationQuestionId: item.id,
            type: item.type,
          });

          if (!radio) {
            nullOptionArr.push(item.evaluationQuestionOrder);
          }
        } else {
          // console.log(this.$refs[refStr][0].checkList);
          if (
            !this.$refs[refStr][0].checkList ||
            this.$refs[refStr][0].checkList.length == 0
          ) {
            nullOptionArr.push(item.evaluationQuestionOrder);
          } else {
            optionArr.push({
              answer: this.$refs[refStr][0].checkList.sort().join(","),
              evaluationQuestionId: item.id,
              type: item.type,
            });
          }
        }
      });
      if (nullOptionArr.length > 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: `第${nullOptionArr.join(",")}题未勾选，请选择后提交！`,
        });
        return;
      }
      let data = {
        classNum: this.indexStudent.classNum,
        evaluationQuestionVOS: optionArr,
        id: this.$route.query.id,
        studentId: this.indexStudent.studentId,
        teacherRole: this.roleList[this.indexRole].teacherRole,
        subjectId: this.roleList[this.indexRole].subjectId,
        dormitory: this.indexStudent.dormitory,
        floor: this.indexStudent.floor,
      };
      this.evaluationteacherSubmit(data);
    },
    // 提交教师打分
    evaluationteacherSubmit(data) {
      this.btnLoading = true;
      evaluationteacherSubmit(data)
        .then(() => {
          if (this.indexStudent.isMark == 0) {
            this.roleList[this.indexRole].totalMark++;
          }
          this.indexStudent.isMark = 1;
          this.btnLoading = false;
          this.dialogVisible = true;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    scroll(type) {
      if (type) {
        this.$refs.roleBox.scrollLeft = this.$refs.roleBox.scrollLeft + 40;
      } else {
        if (this.$refs.roleBox.scrollLeft - 40 <= 0) {
          this.$refs.roleBox.scrollLeft = 0;
        } else {
          this.$refs.roleBox.scrollLeft = this.$refs.roleBox.scrollLeft - 40;
        }
      }
    },

    // 获取学生列表
    getStudents() {
      let data = {
        id: this.$route.query.id,
        teacherRole: this.roleList[this.indexRole].teacherRole,
        subjectId: this.roleList[this.indexRole].subjectId,
      };
      Object.assign(data, this.search);
      getStudents(data).then((res) => {
        if (res.data.data.length > 0) {
          this.studentList = res.data.data;
          this.indexStudent = res.data.data[0];
          this.studentScore();
        } else {
          this.studentList = [];
          this.indexStudent = {};
        }
      });
    },
    // 获取学生列表
    studentScore() {
      let data = {
        id: this.$route.query.id,
        teacherRole: this.roleList[this.indexRole].teacherRole,
        subjectId: this.roleList[this.indexRole].subjectId,
        studentId: this.indexStudent.studentId,
      };
      this.studentScoreList = [];
      studentScore(data).then((res) => {
        // console.log(res);
        this.studentScoreList = res.data.data;
        // this.indexStudent = res.data.data[0];
      });
    },
    // 获取试题列表
    getQuestions() {
      let data = {
        id: this.$route.query.id,
        teacherRole: this.roleList[this.indexRole].teacherRole,
        studentId: "",
      };
      this.questionList = [];
      getQuestions(data).then((res) => {
        res.data.data.map((index) => {
          index.questionContent = index.questionContent
            .replace(/\n/g, "<br/>")
            .replace(/\r/g, "<br/>");
        });
        this.questionList = res.data.data;
        // this.indexStudent = res.data.data[0];
      });
    },
    // 获取班级列表
    getClassnums() {
      let data = {
        id: this.$route.query.id,
      };
      getClassnums(data).then((res) => {
        this.classList = res.data.data;
        // this.indexStudent = res.data.data[0];
      });
    },
    // 获取单个考评详情
    evaluationGetbyid() {
      let data = { id: this.$route.query.id };
      evaluationGetbyid(data).then((res) => {
        this.evaluation = res.data.data;
        // this.classList = res.data.data.classNums.split(",");
      });
    },
    checkTeacher(item, index) {
      this.indexRole = index;
      this.getStudents();
      this.getQuestions();
    },
    // 教师角色列表
    evaluationteacherGetRoles() {
      let data = { id: this.$route.query.id };
      evaluationteacherGetRoles(data).then((res) => {
        this.roleList = res.data.data;
        this.indexRole = 0;
        this.getStudents();
        this.getQuestions();
        // this.classList = res.data.data.classNums.split(",");
      });
    },
  },
};
</script>
<style scoped lang="scss">
.evaluate-answer-sheet {
  .none-data {
    text-align: center;
    width: calc(100% - 444px);
    background-color: #ffffff;
    padding: 360px 0;
    div {
      color: #dddddd;
    }
    // padding: ;
  }
  .model-box {
    .tips-model {
      text-align: center;
      > div {
        font-size: 16px;
        font-weight: 500;
      }
    }
    .el-icon-success {
      font-size: 32px;
      color: #68c23a;
      margin-bottom: 18px;
      // font-weight: bold;
    }
    ::v-deep .el-dialog__footer {
      text-align: center;
      padding-top: 32px;
      .el-button {
        border-radius: 4px;
      }
    }
    ::v-deep .el-dialog__headerbtn {
      right: 14px;
      top: 14px;
    }
    ::v-deep .el-dialog__body {
      padding-top: 10px;
    }
    ::v-deep .el-dialog__header {
      border-bottom: none;
    }
  }
  .btn-box {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    height: 48px;
    margin-bottom: 24px;
  }
  .question-box {
    width: calc(100% - 444px);
    background-color: #ffffff;
    position: relative;
    .question-list-box {
      height: 905px;
    }
  }
  .question-box-title {
    padding: 18px;
    border-bottom: 1px solid #d7d7d7;
  }
  .student-list-box {
    background-color: #ffffff;
    height: 605px;

    .student-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px;
      cursor: pointer;

      .tips {
        width: 58px;
        height: 22px;
        background: #d7d7d7;
        border-radius: 4px;
        text-align: center;
        line-height: 22px;
        color: #ffffff;
      }
      .tips-succ {
        background-color: #2474ed;
      }

      img {
        width: 32px;
        border-radius: 50%;
      }
      span {
        margin-left: 18px;
      }
      .class-num {
        color: #606266;
      }
    }
    .student-on {
      background: #f9fbff;
      position: relative;
      &::after {
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: #2474ed;
        content: "";
        left: 0;
        top: 0;
      }
    }
  }
  .search-student-box {
    border-bottom: 1px solid #f0f0f0;
    padding: 18px;
    background-color: #ffffff;
    ::v-deep .el-input--suffix .el-input__inner {
      padding-left: 8px;
      padding-right: 20px;
    }
    .el-input {
      width: 110px;
    }
    ::v-deep .el-input__suffix {
      right: 0;
    }
    ::v-deep .el-input__inner {
      border-radius: 4px;
    }
    .el-select {
      width: 80px;
    }
  }
  .role-list-box {
    border: 1px solid #e6e7e8;
    background: #f4f7fa;
    position: relative;
    padding: 0 24px;
    height: 49px;

    .role-list {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      div {
        padding: 14px 8px;
        border-right: 1px solid #e6e7e8;
        flex-shrink: 0;
        cursor: pointer;
      }
      .role-on {
        background-color: #ffffff;
      }
      overflow-x: scroll;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    .i-box {
      position: absolute;
      left: 0;
      top: 0;
      border-right: 1px solid #e6e7e8;
      cursor: pointer;
      padding: 14px 4px;
      background: #f4f7fa;
      z-index: 2px;
    }
    .i-box-right {
      right: 0;
      top: 0;
      left: auto;
      border-left: 1px solid #e6e7e8;
      border-right: 0;
    }
  }
  .answer-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 24px 0 60px;
    .answer-left-box {
      width: 426px;
      .student-header-box {
        width: 100%;
        background-color: #ffffff;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        margin-bottom: 24px;
        .tips-list {
          span {
            padding: 4px;
            border-radius: 2px;
            background-color: #ebf3fd;
            color: #2474ed;
            line-height: 14px;
            &:last-child {
              background-color: #e5f9f4;
              color: #04c391;
              margin-left: 12px;
            }
          }
        }
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-bottom: 18px;
        }
        i {
          font-size: 24px;
          font-weight: bold;
          &:hover {
            cursor: pointer;
            color: #2474ed;
          }
        }
      }
    }
  }
}
</style>

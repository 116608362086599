<template>
  <div class="evaluation-item">
    <div class="evaluation-item-top">
      <div class="evaluation-item-top-left">
        <span>{{ question.evaluationQuestionOrder }}</span>
        <span>{{ question.questionDirection }}</span>
      </div>
      <slot></slot>
    </div>
    <div class="evaluate-type">
      <!-- {{ question.questionContent }} -->
      <div v-html="question.questionContent"></div>
      <template v-if="question.type == 1">
        <el-checkbox-group v-model="checkList" :disabled="type">
          <el-checkbox v-for="(v, i) in optionList" :key="i" :label="i"
            >{{ optionArr[i] + "." + v.option }}
            <template v-if="roleId != 5">{{
              "（" + v.score + "分）"
            }}</template>
          </el-checkbox>
        </el-checkbox-group>
      </template>
      <template v-if="question.type == 0">
        <el-radio-group v-model="radio" :disabled="type">
          <el-radio v-for="(v, i) in optionList" :key="i" :label="i"
            >{{ optionArr[i] + "." + v.option }}
            <template v-if="roleId != 5">{{
              "（" + v.score + "分）"
            }}</template>
          </el-radio>
        </el-radio-group>
      </template>
    </div>
  </div>
</template>

<script>
import { optionItem } from "@/core/util/constdata";
import { getStore } from "@/core/util/store";
export default {
  name: "EvaluateType",
  components: {},
  props: {
    question: {
      type: Object,
      default() {},
    },
    type: Boolean,
    index: {
      type: Number,
      default: 0,
    },
    studentScoreList: {
      type: Array,
      default() {},
    },
  },
  data() {
    return {
      radio: "",
      optionArr: optionItem,
      checkList: [],
      optionList: [],
      roleId: 1,
    };
  },
  watch: {
    studentScoreList: {
      deep: true,
      handler() {
        this.initval();
      },
    },
  },
  created() {
    this.roleId = getStore({ name: "userInfo" }).roleId;
    // console.log(this.question.questionContent);
    if (this.question.answerAndScore) {
      this.optionList = JSON.parse(this.question.answerAndScore);
    }
    this.initval();
  },
  methods: {
    initval() {
      if (!this.studentScoreList) {
        return;
      }
      if (this.studentScoreList.length > 0) {
        this.studentScoreList.map((index) => {
          if (this.question.id == index.evaluationQuestionId) {
            // this.radio = "";
            //       this.checkList = [];
            if (this.question.type == 1) {
              this.checkList = index.answer.split(",").map(Number);
            } else {
              this.radio = Number(index.answer);
            }
          }
        });
      } else {
        this.radio = "";
        this.checkList = [];
      }
    },
  },
};
</script>
<style scoped lang="scss">
.evaluation-item {
  background: #ffffff;
  padding: 24px 24px 0;
  margin-bottom: 24px;
  .evaluation-item-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    .el-button {
      color: #5f5f68;
    }
    .evaluation-item-top-left {
      span {
        padding: 4px 10px;
        background-color: #2474ed;
        border-radius: 4px;
        color: #ffffff;
        min-width: 50px;
        display: inline-block;
        text-align: center;
      }
      span + span {
        margin-left: 8px;
        background-color: #04c391;
      }
    }
  }
}
.evaluate-type {
  background: #fcfcfc;
  padding: 16px;
  .el-radio-group {
    margin: 18px 0;
  }
  .el-checkbox-group {
    margin: 18px 0;
  }
  .el-checkbox {
    display: block;
  }
  .el-radio {
    display: block;
  }
}
</style>
